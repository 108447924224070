// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-buy-an-essay-online-html-index-jsx": () => import("./../../../src/pages/buy-an-essay-online.html/index.jsx" /* webpackChunkName: "component---src-pages-buy-an-essay-online-html-index-jsx" */),
  "component---src-pages-cheap-essay-html-index-jsx": () => import("./../../../src/pages/cheap-essay.html/index.jsx" /* webpackChunkName: "component---src-pages-cheap-essay-html-index-jsx" */),
  "component---src-pages-college-essays-html-index-jsx": () => import("./../../../src/pages/college-essays.html/index.jsx" /* webpackChunkName: "component---src-pages-college-essays-html-index-jsx" */),
  "component---src-pages-essay-writer-html-index-jsx": () => import("./../../../src/pages/essay-writer.html/index.jsx" /* webpackChunkName: "component---src-pages-essay-writer-html-index-jsx" */),
  "component---src-pages-essay-writing-help-html-index-jsx": () => import("./../../../src/pages/essay-writing-help.html/index.jsx" /* webpackChunkName: "component---src-pages-essay-writing-help-html-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pay-for-essay-writing-html-index-jsx": () => import("./../../../src/pages/pay-for-essay-writing.html/index.jsx" /* webpackChunkName: "component---src-pages-pay-for-essay-writing-html-index-jsx" */),
  "component---src-pages-write-my-essay-for-me-html-index-jsx": () => import("./../../../src/pages/write-my-essay-for-me.html/index.jsx" /* webpackChunkName: "component---src-pages-write-my-essay-for-me-html-index-jsx" */)
}

