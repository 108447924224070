/**
 * Implement Gatsby's Browser APIs in this file.
 * @see https://www.gatsbyjs.org/docs/browser-apis/
 */


module.exports.disableCorePrefetching = () => true;

module.exports.onClientEntry = () => {
  require('./src/theme/theme.scss');
};
